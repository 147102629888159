body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  background-color: #fff; /* Light mode background */
  color: #000; /* Light mode text color */
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5; /* Light mode header background */
  border-bottom: 1px solid #ddd; /* Light mode header border */
}

#theme-toggle {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  header {
    flex-wrap: nowrap; /* Prevent wrapping */
    justify-content: start;
  }

  #connection-status,
  #theme-toggle {
    margin-left: auto; /* Push to the right */
  }
}

/* Additional styles for dark mode if needed */

#connect {
  padding: 10px 20px;
  background-color: #4caf50; /* Green background for visibility */
  color: white; /* White text for contrast */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  font-size: 1em; /* Adequate text size */
  cursor: pointer; /* Cursor changes to pointer on hover */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  margin-top: 10px; /* Margin for spacing */
}

#connect:hover {
  background-color: #45a049; /* Slightly darker green on hover for feedback */
}

/* Dark mode styles */
.dark-mode #connect {
  background-color: #357a38; /* Darker green for dark mode */
}

.dark-mode #connect:hover {
  background-color: #2e6b32; /* Even darker green on hover in dark mode */
}

.message.faded {
  opacity: 0.5;
}

.transcript-container {
  margin: 10px 0px;
  border-radius: 10px;
  padding: 10px 20px;
  background-color: #f0f0f0;
  color: #000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.dark-mode .transcript-container {
  background-color: #161616;
  color: #fff;
  box-shadow: 0px 4px 8px rgba(229, 229, 229, 0.1);
}

h1,
h2,
p,
ul {
  padding: 0 !important;
  margin: 0 !important;
}
